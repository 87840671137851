import { ISAVector } from './assays';
import { Gene, Indication } from './indications';

export interface VectorFeature {
  name: string;
  type: string;
  start: number;
  end: number;
  strand: number;
  notes: Record<string, string[]>;
  locations: Array<{ start: number; end: number }>;
  featureSets?: string[];
  color?: string;
}

export const NO_INDICATION = '__noIndication';
export const NO_GENE = '__noGene';

export type IndicationMap = {
  [key: string]: {
    [key: string]: FullVector[];
    [NO_GENE]: FullVector[];
  };
  [NO_INDICATION]: {
    [key: string]: FullVector[];
    [NO_GENE]: FullVector[];
  };
};

export interface FullVector extends ISAVector {
  frn: string;
  type: string;
  risk?: number;
  isaVectorId?: string;
  sequence: string;
  features: VectorFeature[];
  entityVersionId?: string;
  indication?: Indication;
  gene?: Gene;
}

// List of metadata keys that can also serve as display names
export const METADATA_KEYS: string[] = [
  'AAV Capsid',
  'Coding Region',
  'Backbone',
  'ITR (bp)',
  'Promoter',
  'UTR',
  'Kozak',
  'Intron',
  'polyA',
  'ITR-ITR (bp)',
];

export type FileVectorRow = {
  id: string;
  field: string;
  value: string;
  fileType: string;
  vectorId: string;
  vectorName: string;
};
